import Maps from "./maps";
import ContactInfo from "./contactinfo";

function Contactparent() {
  return (
    <>
      <ContactInfo />
      <Maps />
    </>
  ); 
}

export default Contactparent;
